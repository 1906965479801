<template>
  <div class="container">
    <div class="one_box">
      <div>
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="keywords"
        >
        </el-input>
      </div>
      <div class="text_search" @click="search_shou">搜索</div>
    </div>
    <div class="two_box">
      <!--学历要求-->
      <div class="filter-list">
        <div class="filter-item">
          <div class="filter-key" style="float: left">学历要求：</div>
          <div class="filter-values" :class="{ filtercollapse: whether }">
            <ul class="clear">
              <li
                :class="{ Active: Active == item.name }"
                v-for="(item, index) in structureList"
                @click="jie_gou(index, item)"
                :key="index"
              >
                <a class="text-color" onclick="return false;">{{
                  item.name
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--按学位要求-->
      <div class="filter-list">
        <div class="filter-item">
          <div class="filter-key" style="float: left">学位要求：</div>
          <div class="filter-values" :class="{ filtercollapse: whether }">
            <ul class="clear">
              <li
                :class="{ Active_two: Active_two == item.name }"
                v-for="(item, index) in structureList_two"
                @click="jie_gou_two(index, item)"
                :key="index"
              >
                <a class="text-color" onclick="return false;">{{
                  item.name
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--行业领域-->
      <div class="filter-list">
        <div class="filter-item">
          <div class="filter-key" style="float: left">行业领域：</div>
          <div class="filter-values" :class="{ filtercollapse: whether }">
            <ul class="clear">
              <li
                :class="{ Active_three: Active_three == item.name }"
                v-for="(item, index) in structureList_three"
                @click="jie_gou_three(index, item)"
                :key="index"
              >
                <a class="text-color" onclick="return false;">{{
                  item.name
                }}</a>
              </li>
            </ul>
          </div>
          <div class="filter-options" style="visibility: visible;">
            <a @click="addclass" href="javascript:;" class="j-filter-more filter-more">{{name}}</a>
          </div>
        </div>
      </div>

      <!--企业性质-->
      <div class="filter-list">
        <div class="filter-item">
          <div class="filter-key" style="float: left">企业性质：</div>
          <div class="filter-values" :class="{ filtercollapse: whether }">
            <ul class="clear">
              <li
                :class="{ Active_four: Active_four == item.name }"
                v-for="(item, index) in structureList_four"
                @click="jie_gou_four(index, item)"
                :key="index"
              >
                <a class="text-color" onclick="return false;">{{
                  item.name
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="three_box">
      <div
        class="li_box"
        @click="ming_cheng(item.id)"
        v-for="(item, index) in bigArry"
        :key="index"
      >
        <div class="top_one">
          <div class="zhi_ye">{{ item.job_name }}</div>
          <div class="qi_ye">{{ item.company_name }}</div>
        </div>
        <div class="top_two">
          <div class="top_two_box">
            <span class="yu_fen">{{ item.salary }}k</span>
            <el-divider direction="vertical"></el-divider>
            <span v-if="item.name_ren == null">0人</span>
            <span>{{ item.name_ren }}</span>
            <el-divider direction="vertical"></el-divider>
            <span v-if="item.education != null">{{ item.education.name }}</span>
            <span v-else>无</span>
            <el-divider direction="vertical"></el-divider>
            <span v-if="item.education != null">{{ item.degree.name }}</span>
            <span v-else>无</span>
          </div>
          <div class="text_box">{{ item.job_describe }}</div>
        </div>
      </div>
      <div class="ye_box">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          layout="total, prev, pager, next"
          :current-page.sync="pageNum"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import zhuanjia from "../common/gd_zhuanjia";
import {
  recruit_lists,
  education,
  degree,
  industry,
  prise,
  rens,
} from "../../common/js/api";

export default {
  name: "Recruit",
  components: {
    zhuanjia,
  },
  data() {
    return {
      name:"展开全部",
      total: 0,
      pageSize: 7,
      pageNum: 1,
      activeName: "first",
      input2: "",
      jie: "",
      jie_two: "",
      jie_three: "",
      jie_four: "",
        whether: true,
      Active: "不限", //荣誉
      Active_two: "不限",
      Active_three: "不限",
      Active_four: "不限",
      structureList: [], //学历
      structureList_two: [],
      structureList_three: [],
      structureList_four: [],
      job: [],
      territory: "", //行业领域
      nature: "", //企业
      education: "", //学历
      degree: "", //学位
      keywords: "",
      bigArry: [],
      renss: [],
    };
  },
  created() {
    this.search_shou();
    this.xue_wei();
    this.ling_yus();
    this.qi_yes();
    this.xue_li();
    this.ren_shu();
  },
  methods: {
      addclass(){
          if(this.whether==true){
              this.whether=false
              this.name="收起"
          }else {
              this.whether=true
              this.name="展开全部"
          }
      },
    //上一页下一页按钮
    currentChange(val) {
      this.pageNum = val;
      this.search_shou();
    },
    //每页显示几条数据按钮
    sizeChange(val) {
      this.pageSize = val;
      this.search_shou();
    },
    ren_shu() {
      rens({})
        .then((res) => {
          this.renss = res.data;
        })
        .catch((err) => {});
    },
    search_shou() {
      recruit_lists({
        territory: this.territory, //行业领域
        nature: this.nature, //企业
        education: this.education, //学历
        degree: this.degree, //学位
        page: this.pageNum,
        limits: this.pageSize,
        keywords: this.keywords,
      })
        .then((res) => {
          console.log(res.data.total);
          this.total = res.data.total;
          this.bigArry = res.data.data;
          for (var i = 0; i < this.bigArry.length; i++) {
            var big = this.bigArry[i];
            var big_num = this.bigArry[i].p_number;
            for (var j = 0; j < this.renss.length; j++) {
              var samll_num = this.renss[j].id;
              if (big_num == samll_num) {
                Object.assign(big, { name_ren: this.renss[j].name });
              }
            }
          }
        })
        .catch((err) => {});
    },
    qi_yes() {
      prise({})
        .then((res) => {
          for (var i = 0; i < res.data.length; i++) {
            Object.assign(res.data[i], { isSelected: false });
          }
          this.structureList_four = res.data;
        })
        .catch((err) => {});
    },
    ling_yus() {
      industry({})
        .then((res) => {
          for (var i = 0; i < res.data.length; i++) {
            Object.assign(res.data[i], { isSelected: false });
          }
          this.structureList_three = res.data;
        })
        .catch((err) => {});
    },
    xue_wei() {
      degree({})
        .then((res) => {
          for (var i = 0; i < res.data.length; i++) {
            Object.assign(res.data[i], { isSelected: false });
          }
          this.structureList_two = res.data;
        })
        .catch((err) => {});
    },
    xue_li() {
      education({})
        .then((res) => {
          for (var i = 0; i < res.data.length; i++) {
            Object.assign(res.data[i], { isSelected: false });
          }
          this.structureList = res.data;
        })
        .catch((err) => {});
    },
    ming_cheng(ids) {
        //  this.$router.push({
        //      path:'/Unitdetails',
        //      query:{
        //          id:ids
        //      }
        //  })
      this.$router.push({
        path: "/positiondetail",
        query: {
          id: ids,
        },
      });
    },
    handleClick(tab, event) {},
    jie_gou(index, item) {
      // 商标结构
      this.education = item.id;
      this.Active = item.name;
      this.jie = item.name;
      this.search_shou();
      if (item.name === "不限") {
        this.jie = "";
      }
    },
    jie_gou_two(index, item) {
      // 商标结构
      this.degree = item.id;
      this.Active_two = item.name;
      this.jie_two = item.name;
      this.search_shou();
      if (item.name === "不限") {
        this.jie = "";
      }
    },
    jie_gou_three(index, item) {
      // 商标结构
      this.territory = item.id;
      this.Active_three = item.name;
      this.jie_three = item.name;
      this.search_shou();
      if (item.name === "不限") {
        this.jie_three = "";
      }
    },
    jie_gou_four(index, item) {
      // 商标结构
      this.Active_four = item.name;
      this.nature = item.id;
      this.jie_four = item.name;
      this.search_shou();
      if (item.name === "不限") {
        this.jie_four = "";
      }
    },
  },
};
</script>

<style scoped lang="less">
  /deep/.el-input__icon{
    line-height: 35px;
  }
.ye_box {
  position: absolute;
  bottom: 40px;
  left: 45%;
}
/deep/.el-tabs__active-bar {
  background-color: unset;
}
/deep/.el-tabs__nav {
  position: unset !important;
}
/deep/.el-tabs__nav-wrap {
  padding: 15px;
  background-color: #f7f7f7;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: #fff;
}
/deep/.el-input {
  margin-left: 30px;
}
/deep/.el-input__inner {
  width: 1074px;
  height: 34px;
  border-radius: 53px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(223, 219, 219, 100);
  font-size: 13px;
  text-align: left;
}
.container {
  position: relative;
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 135px;
  .two_box {
    margin: 0 auto;
    background-color: #fff;
    width: 1200px;
    height: auto;
    line-height: 20px;
    border-radius: 0px 5px 5px 0px;
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    padding-bottom: 15px;
  }
  .three_box {
    overflow: hidden;
    margin: 30px auto;
    width: 1200px;
    background-color: #fff;
    // height: 760px;
    line-height: 20px;
    border-radius: 0px 5px 5px 0px;
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    padding-bottom: 40px;
    .li_box {
      cursor:pointer;
      .top_one {
        justify-content: space-between;
        margin: 40px 20px 20px;
        display: flex;
        .zhi_ye {
          color: rgba(16, 16, 16, 100);
          font-size: 18px;
          font-weight: 600;
        }
        .qi_ye {
          color: rgba(16, 16, 16, 100);
          font-size: 14px;
        }
      }
      .top_two {
        justify-content: space-between;
        margin: 20px;
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(237, 237, 237, 100);
        .top_two_box {
          span {
            color: rgba(16, 16, 16, 100);
            font-size: 14px;
          }
          .yu_fen {
            margin-right: 8px;
            color: rgba(255, 0, 0, 100);
            font-size: 18px;
          }
        }

        .text_box {
          width: 300px;
          line-height: 20px;
          text-overflow: ellipsis;
          display: -webkit-box;
          text-overflow: -o-ellipsis-lastline;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: rgba(16, 16, 16, 100);
          font-size: 14px;
        }
      }
    }
  }

  .one_box {
    width: 1200px;
    margin: 15px auto;
    height: 53px;
    line-height: 23px;
    background-color: rgba(56, 146, 235, 100);
    color: rgba(51, 51, 51, 100);
    font-size: 16px;
    display: flex;
    align-items: center;
    .text_search {
      cursor:pointer;
      color: #fff;
      margin-left: 30px;
    }
  }
}
.filter-item {
  padding-top: 15px;
  position: relative;
  .filter-key {
    /*float: left;*/
    width: 56px;
    padding-left: 30px;
    padding-right: 30px;
    white-space: nowrap;
    /*overflow: hidden;*/
    margin-top: 15px;
    height: 20px;
    line-height: 20px;
    color: #101010;
    cursor: default;
  }
  .filter-values {
    margin-left: 150px;
    margin-right: 110px;
    /*border-bottom: 1px #f0f0f0 dashed;*/
    color: #b1afaf;
    transition: all 0.3s;
  }
  .filtercollapse {
    height: 35px;
    overflow: hidden;
  }
  .filter-options {
    position: absolute;
    right: 30px;
    top: 14px;
    visibility: hidden;
    .filter-more {
      padding-right: 16px;
      color: #999;
      line-height: 16px;
      display: inline-block;
    }
  }
}
.filter-list .Active a {
  padding: 5px 10px;
  color: #ffffff;
  background: #3892eb;
  font-size: 14px;
  /*color: #00bb00;*/
}
.filter-list .Active_two a {
  padding: 5px 10px;
  font-size: 14px;
  color: #ffffff;
  background: #3892eb;
  /*color: #00bb00;*/
}
.filter-list .Active_three a {
  padding: 5px 10px;
  font-size: 14px;
  color: #ffffff;
  background: #3892eb;
  /*color: #00bb00;*/
}
.filter-list .Active_four a {
  padding: 5px 10px;
  font-size: 14px;
  color: #ffffff;
  background: #3892eb;
  /*color: #00bb00;*/
}
.filter-list li {
  float: left;
  margin: 15px 20px 15px 0px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
}
.cf:after,
.clear:after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}
.text-color {
  color: #b1afaf;
  font-size: 14px;
}
@media screen and (max-width: 1200px) {
	.container{
		margin-top: 15rem;
	}
	.container .one_box{
		width: 98%;
		height: 4rem;
	}
	/deep/.el-input__inner{
		width: 40rem;
		height: 3rem;
		font-size: 1.8rem;
		padding-left: 2.5rem;
		color: #333333;
	}
	/deep/.el-input__prefix{
		top: 0.8rem;
		left: 0.5rem;
	}
	/deep/.el-input{
		font-size: 1.8rem;
	}
	.container .one_box .text_search{
		font-size: 1.8rem;
	}
	.container .two_box{
		width: 98%;
	}
	.filter-item .filter-key{
		width: 8rem;
		font-size: 1.6rem;
		height: 2.5rem;
		line-height: 2.5rem;
	}
	.filter-item .filter-values{
		margin-left: 10rem;
		height: auto;
	}
	.text-color{
		font-size: 1.5rem;
	}
	.filter-list li{
		height: 2.5rem;
		line-height: 2.5rem;
	}
	.filter-item .filter-options{
		display: none;
	}
	.container .three_box{
		width: 98%;
	}
	.container .three_box .li_box .top_one{
		padding-bottom: 1rem;
	}
	.container .three_box .li_box .top_one .zhi_ye{
		font-size: 1.8rem;
	}
	.container .three_box .li_box .top_one .qi_ye{
		font-size: 1.4rem;
	}
	.container .three_box .li_box .top_two .top_two_box .yu_fen{
		font-size: 1.4rem;
	}
	.container .three_box .li_box .top_two .top_two_box span{
		font-size: 1.4rem;
	}
	.container .three_box .li_box .top_two .text_box{
		font-size: 1.4rem;
		line-height: 2.5rem;
		width: 20rem;
	}
	/deep/.el-pagination__total{
		font-size: 1.6rem;
	}
	/deep/.el-icon{
		font-size: 1.5rem;
	}
	.ye_box{
		left: 35%;
	}
}
</style>